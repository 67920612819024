import React from 'react';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer__content">
                <div className="contact">
                    <h5 className="footer__label">
                        Contact
                    </h5>
                    <ul className="contact__list">
                        <li className="contact__link">
                            <a href="https://www.thecharacters.com/" className="link link--contact" target="_blank" rel="noreferrer">
                                The Characters Talent Agency
                            </a>
                            <p className="footer__label">
                                Michael White | Agent Toronto
                            </p>
                        </li>
                        
                    </ul>
                    
                </div>
                <div className="causes">
                    <h5 className="footer__label">
                        Vital Causes
                    </h5>
                    <ul className="causes__list">
                        <li className="causes__link">
                            <a href="https://blacklivesmatter.ca/" className="link link--causes" target="_blank" rel="noreferrer">
                                Black Lives Matter
                            </a>
                        </li>   
                        <li className="causes__link">
                            <a href="https://soytoronto.com/" className="link link--causes" target="_blank" rel="noreferrer">
                                Support 2SLGBTQIA+ Youth
                            </a>
                        </li> 
                        <li className="causes__link">
                            <a href="https://idlenomore.ca/" className="link link--causes" target="_blank" rel="noreferrer">
                                Honour Indigenous Sovereignty
                            </a>
                        </li>
                    </ul>
                </div>
                <a href="https://design.mattlemche.com/web-and-mobile" className="link link--footer" target="_blank" rel="noreferrer">
                    Website design and development by ML Designs
                </a>
            </div>
        </footer>
    );
};

export default Footer;